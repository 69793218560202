<div class="{{cssClass}}">
  <div class="favoris">
    <mat-card>
      <div class="left">
        <div class="icon"><span class="icon_content"><mat-icon>star</mat-icon></span></div>
      </div>
      <div class="right">
        <ng-container *ngIf="cssClass === 'theme__4__fav'">
          <button mat-raised-button matTooltip="{{addTooltip}}"><mat-icon>add</mat-icon>FAVORIS</button>
        </ng-container>
        <ng-container *ngIf="cssClass !== 'theme__4__fav'">
          <ng-container *ngFor="let app of applications">
            <ng-container *ngIf="app.is_favorite">
              <a href="{{app.path}}">
                <div class="icon" matTooltip="{{app.label}}">
              <span class="icon_content">
                <mat-icon >{{(app.logo?app.logo:'apps')}}</mat-icon>
              </span>
                </div>
              </a>
            </ng-container>
          </ng-container>
          <a href="javascript:void(0)" (click)="toggleFavList()">
            <div class="icon add" matTooltip="{{addTooltip}}">
              <span class="icon_content">
                <mat-icon>add</mat-icon>
              </span>
            </div>
          </a>
          <ng-container *ngIf="favChoice">
            <div class="fav-choice">
              <span class="fav-close" (click)="toggleFavList()">
                <mat-icon>close</mat-icon>
              </span>
              <span class="more">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </span>
              <mat-list>
                <mat-list-item *ngFor="let app of applications" [ngClass]="{'selected': app.is_favorite}" (click)="saveFav(app)">
                  <div class="inner">
                    <span class="fav-label">{{app.label}}</span>
                    <span class="fav-icon"><mat-icon>{{(app.is_favorite?'grade':'star_border')}}</mat-icon></span>
                  </div>

                </mat-list-item>
              </mat-list>
              <span class="more">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </mat-card>
  </div>
</div>

